import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useHotkeys } from 'react-hotkeys-hook';
import { useParams } from 'react-router-dom';
import {
  ErrorTheme,
  ErrorTitles,
  GeneralError,
  LoadingOverlay
} from '@holberg/ui-kit';
import cn from 'classnames';
import { EventsTree } from 'components/EventsTree';
import { FindingsActionBar } from 'components/FindingsActionBar';
import {
  FindingsConfirmationModal,
  useConfirmationContext
} from 'components/FindingsConfirmationModal';
import { FindingsConfirmationProvider } from 'components/FindingsConfirmationModal';
import { NameSelectionModal } from 'components/NameSelectionModal';
import { useNameSelectionContext } from 'components/NameSelectionModal/NameSelectionContext';
import { NameSelectionProvider } from 'components/NameSelectionModal/NameSelectionProvider';
import { usePropertiesContext } from 'components/PropertiesProviderContext';
import { ScreenshotSlider } from 'components/ScreenshotSlider/ScreenshotSlider';
import { UnclassifiedExamplesContainer } from 'components/UnclassifiedExamplesContainer';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { useCurrentSessionHasLock } from 'hooks/useCurrentSessionHasLock';
import { useSetLoadingOnMount } from 'hooks/useSetLoadingOnMount';
import { useStudyDetailsLoading } from 'hooks/useStudyDetailsLoading';
import { observer } from 'mobx-react-lite';
import { createShortcutCombination } from 'services/keyboardShortcuts/helpers';
import { shortcutsBaseKeys } from 'services/keyboardShortcuts/shortcutsBaseKeys';

import { CustomDragLayer } from '../CustomDragLayer/CustomDragLayer';

import styles from './Findings.module.scss';

const FindingsInner: React.FC = observer(() => {
  const { id } = useParams<{ id: string }>();

  const messagesStore = useStore(StoreType.Messages);
  const findingsStore = useStore(StoreType.Findings);
  const studyDetailsStore = useStore(StoreType.StudyDetails);
  const descriptionsStore = useStore(StoreType.Descriptions);
  const headModelsStore = useStore(StoreType.HeadModel);
  const findingPropertiesStore = useStore(StoreType.FindingProperties);
  const descriptionPropertyTypeCodesStore = useStore(
    StoreType.DescriptionPropertyTypeCodes
  );

  const isStudyDetailsLoading = useStudyDetailsLoading();
  const { isLoadingOnMount } = useSetLoadingOnMount(
    findingsStore.eventCodesLoading ||
      findingsStore.eventCodingsLoading ||
      findingsStore.eventsLoading ||
      findingsStore.shoppingCartLoading ||
      findingsStore.markerTypesLoading
  );

  const activeDescriptionId = parseInt(id);
  const descriptionDetails = descriptionsStore.descriptionById(
    activeDescriptionId
  )!;

  const { currentSessionHasLock } = useCurrentSessionHasLock(
    descriptionDetails?.patientId
  );

  const readOnly =
    descriptionsStore.isReadOnly(activeDescriptionId) || !currentSessionHasLock;

  const {
    state: nameSelectionState,
    onClose: onNameSelectionClose
  } = useNameSelectionContext()!;
  const confirmationContext = useConfirmationContext()!;
  const { activeCodingId, onCloseProperties } = usePropertiesContext();

  const eventsTreeContainer = useRef<HTMLDivElement | null>(null);

  useEffect(
    () => () => {
      findingsStore.resetSelectionState();
      messagesStore.clearSystemMessages();
    },
    [findingsStore, messagesStore]
  );

  useEffect(() => {
    if (descriptionDetails) {
      findingsStore.loadEventCodes(descriptionDetails.ageConstraints);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descriptionDetails?.ageConstraints]);

  useEffect(() => {
    findingsStore.loadEvents(activeDescriptionId);
  }, [findingsStore, activeDescriptionId]);

  useEffect(() => {
    if (activeDescriptionId !== undefined) {
      findingsStore.loadDescriptionStatus(activeDescriptionId);
      findingsStore.loadShoppingCarts(activeDescriptionId);
    }
  }, [activeDescriptionId, findingsStore]);

  useEffect(() => {
    findingsStore.eventTreeState.getEventTreeSettingsConfig();
  }, [findingsStore.eventTreeState]);

  const unclassifiedExamplesCollapsed = useMemo(
    () => studyDetailsStore.userSettingsConfig.unclassifiedExamplesCollapsed,
    [studyDetailsStore.userSettingsConfig.unclassifiedExamplesCollapsed]
  );

  useHotkeys(
    createShortcutCombination({
      baseKey: shortcutsBaseKeys.CMD,
      key: ';'
    }),
    (event) => {
      event.preventDefault();

      studyDetailsStore.updateUserSettingsConfig({
        unclassifiedExamplesCollapsed: !unclassifiedExamplesCollapsed
      });
    },
    {},
    [unclassifiedExamplesCollapsed]
  );

  useHotkeys(
    createShortcutCombination({
      baseKey: shortcutsBaseKeys.SHIFT,
      key: 'f'
    }),
    (event) => {
      event.preventDefault();
      if (activeCodingId !== undefined) {
        onCloseProperties();
      }
      const firstFindingInEventsTree = eventsTreeContainer.current
        ? eventsTreeContainer.current.querySelectorAll(
            '.finding-header-identifier'
          )[0]
        : null;
      firstFindingInEventsTree &&
        (firstFindingInEventsTree as HTMLElement).focus();
    },
    {},
    [activeCodingId]
  );

  const onToggleUnclassifiedExamples = useCallback(() => {
    studyDetailsStore.updateUserSettingsConfig({
      unclassifiedExamplesCollapsed: !unclassifiedExamplesCollapsed
    });
  }, [studyDetailsStore, unclassifiedExamplesCollapsed]);

  const error =
    findingsStore.eventCodesError ||
    findingsStore.eventsError ||
    findingsStore.eventCodingsError ||
    descriptionsStore.descriptionDetailsError ||
    descriptionPropertyTypeCodesStore.propertyTypeCodesExtraInfoError ||
    findingPropertiesStore.propertyCodingsError ||
    findingPropertiesStore.propertyPanelsError ||
    findingPropertiesStore.propertyTabsError ||
    headModelsStore.headModelError;

  const formattedEventCodings = JSON.stringify(
    findingsStore.eventCodings.get(activeDescriptionId)
  );

  useEffect(() => {
    if (!error) headModelsStore.loadSimpleHeadModels(activeDescriptionId);
  }, [
    findingsStore.reportHeadModel,
    formattedEventCodings,
    activeDescriptionId,
    headModelsStore,
    error
  ]);

  return (
    <LoadingOverlay
      loading={
        (isLoadingOnMount && !isStudyDetailsLoading) ||
        findingsStore.findingsLoading
      }
    >
      <div className={styles.findings}>
        {error ? (
          <GeneralError theme={ErrorTheme.Secondary} title={ErrorTitles.Load} />
        ) : (
          <>
            {!readOnly && (
              <FindingsActionBar
                activeDescriptionId={activeDescriptionId}
                unclassifiedExamplesCollapsed={unclassifiedExamplesCollapsed}
                onExpandUnclassifiedExamples={onToggleUnclassifiedExamples}
              />
            )}
            <div
              className={cn(styles.container, readOnly && styles['read-only'])}
            >
              {!unclassifiedExamplesCollapsed && !readOnly && (
                <div
                  data-testid='unclassified-examples-container'
                  className={styles['unclassified-examples-wrap']}
                >
                  <UnclassifiedExamplesContainer
                    readOnly={readOnly}
                    onCollapseUnclassifiedExamples={
                      onToggleUnclassifiedExamples
                    }
                    isLoading={
                      findingsStore.eventsLoading ||
                      findingsStore.markerTypesLoading
                    }
                  />
                </div>
              )}

              <div
                className={styles['events-trees-wrap']}
                ref={eventsTreeContainer}
              >
                {findingsStore.eventCodings.get(activeDescriptionId) && (
                  <EventsTree
                    readOnly={readOnly}
                    eventTreeData={findingsStore.eventCodesTree}
                  />
                )}
              </div>
            </div>
            {!!findingsStore.eventCodesByAgeConstraint.length && (
              <NameSelectionModal
                defaultActiveCodeId={nameSelectionState.activeEventCodeId}
                disabledEventCodeIds={nameSelectionState.disabledEventCodeIds}
                visible={nameSelectionState.isOpen}
                contentHidden={confirmationContext.state.isOpen}
                onClose={onNameSelectionClose}
                onSelect={nameSelectionState.onSelect!}
                title={nameSelectionState.title!}
              />
            )}
            <FindingsConfirmationModal
              onUpdateSettingsConfig={(hideWarnings) =>
                studyDetailsStore.updateUserSettingsConfig({
                  skipPropertiesWarnings: hideWarnings
                })
              }
              transparent={nameSelectionState.isOpen}
            />
          </>
        )}
      </div>
    </LoadingOverlay>
  );
});

export const Findings: React.FC = observer(() => {
  const studyStore = useStore(StoreType.StudyDetails);
  return (
    <DndProvider backend={HTML5Backend}>
      <FindingsConfirmationProvider
        skipWarnings={studyStore.userSettingsConfig.skipPropertiesWarnings}
      >
        <NameSelectionProvider>
          <FindingsInner />
          <CustomDragLayer />
          <ScreenshotSlider />
        </NameSelectionProvider>
      </FindingsConfirmationProvider>
    </DndProvider>
  );
});
