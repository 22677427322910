import React, { useCallback, useEffect, useMemo } from 'react';
import { generatePath, NavLink } from 'react-router-dom';
import {
  Icon,
  IconType,
  KeyValueRow,
  Switch,
  useCustomTranslation,
  WarningMessage,
  WarningMessageTheme
} from '@holberg/ui-kit';
import cn from 'classnames';
import { CategoricalPropertyCoding } from 'entities/CategoricalPropertyCoding.entity';
import { PatientDetails } from 'entities/PatientDetails.entity';
import { PatientPropertyTypes } from 'enums/PatientPropertyType.enum';
import {
  RealTimeUpdateReceiveMessages,
  RealTimeUpdateSendMessages
} from 'enums/RealTimeUpdateType.enum';
import { Routes } from 'enums/Routes.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { useActivityTracker } from 'hooks/useActivityTracker';
import { usePageMatch } from 'hooks/usePageMatch';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { observer } from 'mobx-react-lite';
import { RTUManager } from 'services/RealTimeUpdatesManager';
import { REPORT_INFO_BOTTOM_VIEW_MAX_WIDTH } from 'utils/constants';

import styles from './PatientDetailsHeader.module.scss';

interface Props {
  patientPropertyCodings: Record<number, CategoricalPropertyCoding[]>;
  patientDetails: PatientDetails;
}

export const PatientDetailsHeader: React.FC<Props> = observer(
  ({ patientPropertyCodings, patientDetails }) => {
    const { t } = useCustomTranslation();
    const patientLockDetailsStore = useStore(StoreType.PatientLockDetails);
    const rtuStore = useStore(StoreType.RealTimeUpdates);
    const {
      startTracker,
      stopTracker,
      getLastActiveTime
    } = useActivityTracker();
    const patientId = patientDetails.patientId;
    const patientLockDetails = patientLockDetailsStore.patientLockDetails.get(
      patientId
    );
    const sessionHaveEditLock = patientLockDetails?.sessionHasLock || false;
    const isPatientLocked =
      !sessionHaveEditLock && patientLockDetails?.isLocked;
    const {
      fullName,
      identityString,
      formattedDateOfBirth,
      formattedAge
    } = patientDetails;

    const { windowWidth } = useWindowWidth();
    const hidePatientDetailsTitle = useMemo(
      () => windowWidth < REPORT_INFO_BOTTOM_VIEW_MAX_WIDTH,
      [windowWidth]
    );

    const isPatientOverviewPage = usePageMatch(Routes.PatientOverview);

    const renderHeaderRow = useCallback(
      (title: string, value?: string | number) =>
        !!value && (
          <KeyValueRow
            title={hidePatientDetailsTitle ? '' : t(title)!}
            value={value}
            className={styles.row}
            titleClassName={styles['row-title']}
          />
        ),
      [hidePatientDetailsTitle, t]
    );

    const genderPropertyCoding =
      patientPropertyCodings[PatientPropertyTypes.Gender] || [];
    const gender =
      genderPropertyCoding.length &&
      genderPropertyCoding[0].selectOptionShape?.label;

    useEffect(() => {
      RTUManager.addObservers([
        {
          message: RealTimeUpdateReceiveMessages.UpdatePatientLockStatus,
          callback: (data) => {
            patientLockDetailsStore.patientLockDetails.set(
              patientId,
              data.status
            );
          }
        }
      ]);
      patientLockDetailsStore.sendPatientLockMessage(
        patientId,
        RealTimeUpdateSendMessages.GetPatientLockStatus,
        false
      );
      patientLockDetailsStore.sendPatientLockMessage(
        patientId,
        RealTimeUpdateSendMessages.PatientOpened,
        false
      );
      return () => {
        patientLockDetailsStore.lastOpenedPatientId = patientId;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId, rtuStore.realTimeUpdatesConfig]);

    useEffect(() => {
      let interval: ReturnType<typeof setInterval>;
      let timeout: ReturnType<typeof setTimeout>;
      if (sessionHaveEditLock) {
        startTracker(); //starts tracking user activity
        interval = setInterval(() => {
          if (getLastActiveTime() < 30) {
            patientLockDetailsStore.sendPatientLockMessage(
              patientId,
              RealTimeUpdateSendMessages.RefreshPatientEditLock,
              false
            ); //sends refresh lock message if user is active in last 30 seconds
          }
        }, 30000);
      } else if (isPatientLocked && patientLockDetails?.validInSeconds) {
        timeout = setTimeout(() => {
          patientLockDetailsStore.sendPatientLockMessage(
            patientId,
            RealTimeUpdateSendMessages.GetPatientLockStatus,
            false
          );
        }, patientLockDetails.validInSeconds * 1000);
      }
      return () => {
        if (interval) clearInterval(interval);
        if (timeout) clearTimeout(timeout);
        stopTracker();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientLockDetails, patientId]);

    function toggleEditMode() {
      if (isPatientLocked) return;
      sessionHaveEditLock
        ? patientLockDetailsStore.sendPatientLockMessage(
            patientId,
            RealTimeUpdateSendMessages.ReleasePatientEditLock
          )
        : patientLockDetailsStore.sendPatientLockMessage(
            patientId,
            RealTimeUpdateSendMessages.RequestPatientEditLock
          );
    }

    return (
      <div className={styles['patient-details-header']}>
        <div className={styles['main-header']}>
          <div className={styles['patient-info']}>
            {isPatientOverviewPage ? (
              <div className={styles.title}>
                <Icon
                  iconType={IconType.Profile}
                  className={styles['profile-icon']}
                />
                {fullName}
              </div>
            ) : (
              <NavLink
                exact
                className={cn(styles['patient-link'], styles['title'])}
                to={generatePath(Routes.PatientOverview, {
                  id: patientId
                })}
              >
                <Icon
                  iconType={IconType.Profile}
                  className={styles['profile-icon']}
                />
                {fullName}
              </NavLink>
            )}
            {renderHeaderRow('Patient ID', identityString)}
            {renderHeaderRow('Gender', gender)}
            {renderHeaderRow('Date of birth', formattedDateOfBirth)}
            {renderHeaderRow('Age', formattedAge)}
          </div>
          <div className={styles['switch-wrap']}>
            <span className={styles['switch-text']}>Edit mode</span>
            <Switch
              checked={sessionHaveEditLock}
              onChange={toggleEditMode}
              disabled={isPatientLocked}
            />
          </div>
        </div>
        {!sessionHaveEditLock && (
          <div className={styles['warning-msg']}>
            <WarningMessage
              theme={WarningMessageTheme.Yellow}
              content={
                <p>
                  {isPatientLocked
                    ? `${patientLockDetails?.userInfo?.userName} is editing. You are viewing this patient's content in read-only mode.`
                    : `You are viewing this patient's content in read-only mode, activate edit mode to make changes.`}
                </p>
              }
            />
          </div>
        )}
      </div>
    );
  }
);
