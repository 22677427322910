import React, { useEffect, useRef } from 'react';
import { useDrop } from 'react-dnd';
import { generatePath } from 'react-router-dom';
import {
  Accordion,
  Icon,
  IconType,
  rebuildTooltips,
  useCustomTranslation
} from '@holberg/ui-kit';
import cn from 'classnames';
import { Recording } from 'components/Recording/Recording';
import { ReportTileHeader } from 'components/ReportTileHeader';
import { Report } from 'entities/Report.entity';
import { Study } from 'entities/Study.entity';
import { DraggableTypes } from 'enums/DraggableTypes.enum';
import { Routes } from 'enums/Routes.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { observer } from 'mobx-react-lite';
import { history } from 'services/history';

import styles from './ReportTile.module.scss';

interface Props {
  moveRecordings: (reportId: number) => void;
  reopenCompletedReport: (reportId: number) => void;
  deleteReport: (reportId: number) => void;
  report: Report;
  sessionHaveEditLock: boolean;
}

export const ReportTile: React.FC<Props> = observer(
  ({
    report,
    moveRecordings,
    reopenCompletedReport,
    deleteReport,
    sessionHaveEditLock = false
  }) => {
    const { t } = useCustomTranslation();
    const patientReportsStore = useStore(StoreType.PatientReports);
    const reportId = report.description!.descriptionId;

    const activeReport = useRef<HTMLDivElement | null>(null);
    const isReportActive = patientReportsStore.activeReportId === reportId;

    const diagnosticSignificance = report.description?.diagnosticSignificance;

    const expandAccordion = patientReportsStore.isReportsRecordingsExpanded(
      reportId
    );

    const onRecordingToggle = (recording: Study) => {
      patientReportsStore.toggleReportedRecordings(recording);
    };

    const onReportToggle = (reportId: number) => {
      patientReportsStore.toggleReports(reportId);
    };

    const isDroppable =
      !report.description?.isCompleted &&
      (patientReportsStore.selectedReportedRecordings.size > 0 ||
        patientReportsStore.selectedUnreportedRecordings.size > 0) &&
      !report.studies.some(
        (study) =>
          !study.isOnline ||
          study.studyId ===
            patientReportsStore.getSelectedReportedRecordings[0]?.studyId
      ) &&
      !patientReportsStore.getSelectedReportedRecordings.some(
        (study) => !study.isOnline
      ) &&
      patientReportsStore.selectedReports.size < 1;

    const [{ isOver }, drop] = useDrop(
      () => ({
        accept: [DraggableTypes.recording],
        canDrop: () => isDroppable,
        drop: (_item: any, monitor) => {
          if (monitor.didDrop()) {
            return;
          }
          moveRecordings(reportId);
        },
        collect: (monitor) => ({
          isOver: monitor.isOver()
        })
      }),
      [isDroppable]
    );

    useEffect(() => {
      rebuildTooltips();
    }, [isDroppable, report]);

    useEffect(() => {
      activeReport.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReportActive]);

    return (
      <div
        className={cn(
          styles['report-tile'],
          isReportActive ? styles['flash-report-border'] : ''
        )}
        ref={drop}
        style={{ borderColor: isOver && isDroppable ? '#2f75ff' : '' }}
        data-testid='report-tile-container'
        onDoubleClick={(e) => {
          e.stopPropagation();
          history.push(
            generatePath(Routes.StudyFindings, {
              id: String(reportId)
            })
          );
        }}
      >
        <ReportTileHeader
          report={report}
          isLocked={!sessionHaveEditLock}
          isDroppable={isDroppable}
          moveRecordings={moveRecordings}
          reopenCompletedReport={reopenCompletedReport}
          deleteReport={deleteReport}
          onReportToggle={onReportToggle}
        />
        <div
          className={styles['report-tile-content']}
          ref={isReportActive ? activeReport : null}
        >
          <Accordion
            className={styles['report-accordion']}
            hasLadder={false}
            toggleDisabled={false}
            isActive={expandAccordion}
            onArrowClick={() => {
              return;
            }}
            contentClassName={styles['report-accordion-content']}
            renderHeader={(arrow) => {
              return (
                <div
                  className={cn(
                    styles['report-accordion-header'],
                    styles['flex-sb'],
                    expandAccordion && styles['expanded-accordion-header']
                  )}
                  onDoubleClick={(e) => e.stopPropagation()}
                  onClick={(e) => {
                    e.stopPropagation();
                    patientReportsStore.toggleRecordingsAccordion(
                      report.description!.descriptionId
                    );
                  }}
                  data-testid='reported-recordings-header'
                >
                  <div className={styles['flex-sb']}>
                    <Icon
                      iconType={IconType.Recordings}
                      className={styles['icon']}
                    />
                    <p>
                      {t('Recordings')} <span>({report.studies.length})</span>
                    </p>
                  </div>
                  {arrow}
                </div>
              );
            }}
          >
            <div>
              {report.studies.map((recording: Study) => (
                <Recording
                  key={recording.studyId}
                  isLocked={!sessionHaveEditLock}
                  isDisabled={
                    !patientReportsStore.isRecordingSelectable(
                      report.description,
                      recording.studyId
                    )
                  }
                  recording={recording}
                  onSelect={onRecordingToggle}
                  report={report.description}
                  isFocused={patientReportsStore.focusedRecordingIds?.includes(
                    recording.studyId
                  )}
                />
              ))}
            </div>
          </Accordion>
          {diagnosticSignificance && (
            <div className={styles['diagnostic-div']}>
              <p className={styles['div-heading']}>
                <Icon
                  iconType={IconType.Amplitude}
                  className={styles['icon']}
                />
                <span>{t('Diagnostic significance')}</span>
              </p>

              <>
                <p className={styles['diagnostic-title']}>
                  {diagnosticSignificance?.value}
                </p>
                <span className={styles['diagnostic-freetext']}>
                  {diagnosticSignificance?.freeText}
                </span>
              </>
            </div>
          )}
        </div>
      </div>
    );
  }
);
