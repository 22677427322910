import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useParams } from 'react-router-dom';
import ViewportList from 'react-viewport-list';
import {
  Button,
  ButtonSize,
  ButtonTheme,
  GeneralError,
  IconType,
  rebuildTooltips,
  TextBadge,
  Tooltip,
  useCustomTranslation
} from '@holberg/ui-kit';
import cn from 'classnames';
import { Example } from 'components/Example';
import {
  ConfirmationTitle,
  useConfirmationContext
} from 'components/FindingsConfirmationModal';
import { useNameSelectionContext } from 'components/NameSelectionModal/NameSelectionContext';
import { usePropertiesContext } from 'components/PropertiesProviderContext';
import { Event } from 'entities/Event.entity';
import { EventCoding } from 'entities/EventCoding.entity';
import { DraggableTypes } from 'enums/DraggableTypes.enum';
import { MarkerGroups } from 'enums/MarkerGroups.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { observer } from 'mobx-react-lite';
import { shortcutsBaseTitles } from 'services/keyboardShortcuts/shortcutsBaseKeys';

import findingsStyles from '../Findings/Findings.module.scss';
import styles from './UnclassifiedExamplesContainer.module.scss';

export const BTN_COLLAPSE_EXAMPLES = 'btn-collapse-examples';

interface Props {
  isLoading?: boolean;
  onCollapseUnclassifiedExamples: () => void;
  readOnly: boolean;
}

interface MenuItem {
  id: number;
  title: string | null;
  subMenu: { id: number; name: string; eventsCount: number }[] | null;
}

export const UnclassifiedExamplesContainer: React.FC<Props> = observer(
  ({ onCollapseUnclassifiedExamples, isLoading, readOnly }) => {
    const { id } = useParams<{ id: string }>();
    const { t } = useCustomTranslation();
    const {
      activeCodingId,
      onFindingClick,
      onCloseProperties
    } = usePropertiesContext();

    const hasActiveCoding = activeCodingId !== undefined;
    const lastCheckedExample = useRef<number>(-1);
    const windowingRef = useRef(null);
    const scrollRef = useRef({ scrollToIndex: (index: any) => index });

    const findingsStore = useStore(StoreType.Findings);
    const reportsStore = useStore(StoreType.PatientReports);
    const reportDetails = reportsStore.patientReports.get(parseInt(id))!;
    const rtuStore = useStore(StoreType.RealTimeUpdates);

    const activeDescriptionId = parseInt(id);
    const nameSelectionContext = useNameSelectionContext();
    const confirmationContext = useConfirmationContext();
    const [menuItems, setMenuItems] = useState<MenuItem[] | null>(null);
    //For descriptions or reports with autoscore events, events or examples are divided into autoscore categories
    //Non-autoscore examples are in graphoelements category

    const [selectedMenuItemId, setSelectedMenuItemId] = useState({
      menuId: MarkerGroups.GraphoElements,
      subMenuId: MarkerGroups.GraphoElements
    });

    const [isMenuPinned, setIsMenuPinned] = useState(true);

    const [data, setData] = useState<Event[]>([]);
    const canDrop = selectedMenuItemId.menuId === MarkerGroups.GraphoElements;
    const activeExampleId = useRef(0);

    const [{ isOver }, drop] = useDrop(
      () => ({
        accept: [DraggableTypes.example, DraggableTypes.finding],
        canDrop: () => canDrop,
        drop: async (item: any, monitor) => {
          if (monitor.didDrop()) {
            return;
          }
          if (
            monitor.getItemType() === DraggableTypes.example &&
            item.eventCodingId !== null
          ) {
            await findingsStore.deleteEvents(
              activeDescriptionId,
              findingsStore.selectionState.draggedExample
                ? [findingsStore.selectionState.draggedExample.eventId]
                : findingsStore.selectionState.selectedExamples.map(
                    (example) => example.eventId
                  )
            );
          } else if (monitor.getItemType() === DraggableTypes.finding) {
            await findingsStore.deleteEventCodings(
              activeDescriptionId,
              findingsStore.selectionState.draggedFinding
                ? [
                    findingsStore.selectionState.draggedFinding?.item
                      .eventCodingId
                  ]
                : findingsStore.selectionState.selectedFindingsIds
            );
          }
          findingsStore.selectionState.discardSelections();
        },
        collect: (monitor) => ({
          isOver: monitor.isOver()
        })
      }),
      [selectedMenuItemId]
    );

    useEffect(() => {
      //to scroll near activated event
      if (scrollRef.current) {
        if (activeExampleId.current !== 0) {
          scrollRef.current.scrollToIndex(
            data.findIndex((event) => event.eventId === activeExampleId.current)
          );
        } else {
          scrollRef.current.scrollToIndex(0);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMenuItemId, scrollRef]);

    useEffect(() => {
      rebuildTooltips();
    }, []);

    useEffect(() => {
      const unclassifiedCategories = findingsStore.getUnclassifiedCategories(
        activeDescriptionId
      );
      setMenuItems(unclassifiedCategories);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      activeDescriptionId,
      findingsStore.autoScoreMarkerTypes,
      findingsStore.unclassifiedEvents
    ]);

    useEffect(() => {
      if (menuItems) {
        setData(
          findingsStore.unclassifiedExamplesList(selectedMenuItemId.subMenuId)
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      menuItems,
      selectedMenuItemId,
      activeDescriptionId,
      findingsStore.unclassifiedEvents,
      findingsStore.selectionState.selectionSize
    ]);

    useEffect(() => {
      //to expand menu and submenu based on activated event
      const activeEvent = findingsStore.activeUnclassifiedEvent;
      if (activeEvent) {
        const autoScoreMarkerTypeIds = findingsStore.autoScoreMarkerTypes.map(
          (markerType) => markerType.markerTypeId
        );
        if (autoScoreMarkerTypeIds.includes(activeEvent.markerTypeId)) {
          setSelectedMenuItemId({
            menuId: MarkerGroups.AutoScore,
            subMenuId: activeEvent.markerTypeId
          });
        } else {
          setSelectedMenuItemId({
            menuId: MarkerGroups.GraphoElements,
            subMenuId: MarkerGroups.GraphoElements
          });
        }
        activeExampleId.current = activeEvent.eventId;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [findingsStore.activeUnclassifiedEvent]);

    const onDoubleClick = (studyId: number, exampleId: Event['eventId']) => {
      rtuStore.activateEvent(studyId, activeDescriptionId, exampleId);
    };

    const createEventCoding = useCallback(
      async (eventCodeId: number, exampleId: Event['eventId']) => {
        const eventCodings: EventCoding[] = await findingsStore.createEventCoding(
          {
            data: {
              eventIds: [exampleId],
              eventCodeId
            },
            descriptionId: activeDescriptionId,
            identifier: `example-classify-${exampleId}`
          }
        );

        const activeEventCoding = eventCodings.find(
          (eventCoding) => eventCoding.eventCodeId === eventCodeId
        );

        !!eventCodings.length &&
          !findingsStore.isToBeDefined(eventCodeId) &&
          onFindingClick(activeEventCoding);
      },
      [activeDescriptionId, findingsStore, onFindingClick]
    );

    const onClassify = useCallback(
      (exampleId: Event['eventId']) => {
        nameSelectionContext?.onOpen({
          title: t('Classify'),
          onSelect: (eventCodeId) => {
            if (
              findingsStore.hasActiveOnlyOneEventCoding(
                activeDescriptionId,
                eventCodeId
              )
            ) {
              confirmationContext?.onOpen({
                title: t(ConfirmationTitle.Add_Second_OnlyOneEventCoding),
                submitButtonTitle: t('Yes, sure'),
                onSubmit: () => createEventCoding(eventCodeId, exampleId)
              });
            } else {
              createEventCoding(eventCodeId, exampleId);
            }

            nameSelectionContext.onClose();
          }
        });
      },
      [
        activeDescriptionId,
        confirmationContext,
        createEventCoding,
        findingsStore,
        nameSelectionContext,
        t
      ]
    );

    const formattedDateOfEvent = useCallback(
      (event: Event) => {
        return event.formatStartDateTime(
          reportDetails?.reportStartDateTime,
          reportDetails?.reportStopDateTime
        );
      },
      [reportDetails?.reportStartDateTime, reportDetails?.reportStopDateTime]
    );

    const getSelectedMenuItemDisplayText = () => {
      if (!menuItems) return;
      const selectedItem = menuItems
        .find((item) => item.id === selectedMenuItemId.menuId)
        ?.subMenu?.find((each) => each.id === selectedMenuItemId.subMenuId);
      return (
        `${selectedItem?.name}` +
        (selectedItem?.eventsCount === 0
          ? ''
          : ` (${selectedItem?.eventsCount})`)
      );
    };

    const onDeletingExample = (eventId: number) => {
      confirmationContext?.onOpen({
        title: ConfirmationTitle.Delete_Example,
        submitButtonTitle: 'Yes, delete',
        onSubmit: () =>
          findingsStore.deleteEEGMarker(activeDescriptionId, [eventId])
      });
    };

    return (
      <div
        className={cn(
          styles['unclassified-examples-container'],
          isOver && canDrop ? findingsStyles['on-dropover'] : ''
        )}
        ref={drop}
        data-testid='unclassify-droppable-area'
      >
        <div className={styles.header}>
          <h5 className={styles['title']}>{t('Unclassified examples')}</h5>
          <Tooltip
            data={[
              {
                mainTooltip: (
                  <span>
                    {t('Collapse')}
                    <TextBadge title={shortcutsBaseTitles.CMD} />
                    +
                    <TextBadge title={t(';')} />
                  </span>
                )
              }
            ]}
          >
            <Button
              data-testid={BTN_COLLAPSE_EXAMPLES}
              onClick={onCollapseUnclassifiedExamples}
              size={ButtonSize.Small}
              icon={IconType.SkipArrow}
              theme={ButtonTheme.SecondaryTransparent}
              className={styles['btn-collapse']}
            />
          </Tooltip>
        </div>
        {menuItems?.length !== 0 && !isLoading && (
          <div className={styles['menu']}>
            <Button
              onClick={() => setIsMenuPinned(!isMenuPinned)}
              icon={IconType.CaretDown}
              theme={ButtonTheme.Secondary}
              className={cn(
                styles['menu-collapse'],
                isMenuPinned ? styles['pinned'] : ''
              )}
            />
            <div className={styles['inner-menu']}>
              {!isMenuPinned && menuItems && (
                <div className={cn(styles['menu-item'], styles['selected'])}>
                  {getSelectedMenuItemDisplayText()}
                </div>
              )}
              <div
                className={cn(
                  styles['overlay'],
                  isMenuPinned ? styles['overlay-pinned'] : ''
                )}
              >
                {menuItems && (
                  <>
                    {menuItems.map((item) => {
                      return (
                        <div key={item.id}>
                          {item.title ? <p>{item.title}</p> : <></>}
                          <ul
                            className={
                              styles[!item.title ? 'notitle-submenu' : '']
                            }
                          >
                            {item.subMenu?.map((each) => (
                              <li
                                key={each.id}
                                className={cn(
                                  styles['menu-item'],
                                  item.id === selectedMenuItemId.menuId &&
                                    each.id === selectedMenuItemId.subMenuId
                                    ? styles['selected']
                                    : ''
                                )}
                                onClick={() =>
                                  setSelectedMenuItemId({
                                    menuId: item.id,
                                    subMenuId: each.id
                                  })
                                }
                              >
                                {each.name}{' '}
                                {each.eventsCount ? (
                                  <span>({each.eventsCount})</span>
                                ) : (
                                  <></>
                                )}
                              </li>
                            ))}
                          </ul>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        <div className={styles['list-wrap']} ref={windowingRef}>
          {!isLoading && (
            <div
              className={cn([
                styles.list,
                data?.length < 1 && styles['list-stretch-height']
              ])}
            >
              {data?.length ? (
                <ViewportList
                  items={data}
                  ref={scrollRef}
                  viewportRef={windowingRef}
                >
                  {(item: Event, index: number) => {
                    const exampleState = findingsStore.selectionState.getExampleState(
                      item
                    );
                    return (
                      <Example
                        readOnly={readOnly}
                        onDoubleClick={onDoubleClick}
                        key={item.eventId}
                        index={index}
                        eventDetails={item}
                        onSelect={(e) => {
                          if (hasActiveCoding) {
                            onCloseProperties();
                          }
                          if (
                            e.nativeEvent.shiftKey &&
                            lastCheckedExample.current !== -1
                          ) {
                            const selectedExamples = data.slice(
                              Math.min(index, lastCheckedExample.current),
                              Math.max(index, lastCheckedExample.current) + 1
                            );
                            findingsStore.selectionState.selectMultipleExamples(
                              selectedExamples
                            );
                          } else {
                            findingsStore.selectionState.toggleExample(item);
                          }
                          lastCheckedExample.current = index;
                        }}
                        onClassify={onClassify}
                        onDelete={onDeletingExample}
                        className={styles['list-item']}
                        isActive={
                          rtuStore.realTimeUpdatesConfig.activeEventId ===
                          item.eventId
                        }
                        eventDate={formattedDateOfEvent(item) || ''}
                        isSelected={exampleState.selected}
                        isCheckboxDisabled={exampleState.checkboxDisabled}
                        isActionsAvailable={exampleState.actionsAvailable}
                        showProbability={
                          selectedMenuItemId.menuId !==
                          MarkerGroups.GraphoElements
                        }
                      />
                    );
                  }}
                </ViewportList>
              ) : (
                <div className={styles['empty-list']}>
                  <div className={styles['empty-list-container']}>
                    <GeneralError
                      className={styles['general-error']}
                      icon={IconType.Empty}
                      title={t('This list looks empty')}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
);
