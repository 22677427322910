import React, { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useParams } from 'react-router-dom';
import {
  Button,
  ButtonSize,
  ButtonTheme,
  ConfirmationModal,
  GeneralError,
  IconType,
  LoadingOverlay,
  useCustomTranslation
} from '@holberg/ui-kit';
import cn from 'classnames';
import { CustomDragLayer } from 'components/CustomDragLayer/CustomDragLayer';
import { ConfirmationTitle } from 'components/FindingsConfirmationModal';
import { withLayout } from 'components/Layout';
import { PatientDetailsHeader } from 'components/PatientDetailsHeader';
import { ReportTile } from 'components/ReportTile';
import { UnreportedRecordings } from 'components/UnreportedRecordings/UnreportedRecordings';
import { Report } from 'entities/Report.entity';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { useCurrentSessionHasLock } from 'hooks/useCurrentSessionHasLock';
import { useStudyDetailsLoading } from 'hooks/useStudyDetailsLoading';
import { observer } from 'mobx-react-lite';

import styles from './PatientOverviewPage.module.scss';

interface confirmationModalContentType {
  confirmationText: string;
  actionType: string;
  destinationReport: undefined | number;
}

export const PatientOverviewPage: React.FC = withLayout(
  (hasConnectionIssues: boolean) =>
    hasConnectionIssues ? styles['snack-wrapper'] : styles.wrapper
)(
  observer(() => {
    const { t } = useCustomTranslation();
    const params = useParams<{ id: string }>(),
      patientId = parseInt(params.id),
      patientReportsStore = useStore(StoreType.PatientReports),
      patientDetailsStore = useStore(StoreType.PatientDetails),
      patientDetails = patientDetailsStore.patientById(params.id),
      patientPropertyCodings = patientDetailsStore.propertyCodings(params.id),
      patientReports = patientReportsStore.getReports,
      unreportedRecordings = patientReportsStore.getUnreportedRecordings,
      [showConfirmationModel, setShowConfirmationModel] = useState<boolean>(
        false
      ),
      [
        confirmationModalContent,
        setConfirmationModalContent
      ] = useState<confirmationModalContentType>({
        confirmationText: '',
        actionType: '',
        destinationReport: undefined //report into which recordings are moved
      });

    const { currentSessionHasLock } = useCurrentSessionHasLock(patientId);

    const isPatientOverviewLoading = useStudyDetailsLoading();

    const selectedRecordings =
      patientReportsStore.selectedUnreportedRecordings.size +
      patientReportsStore.selectedReportedRecordings.size;

    useEffect(() => {
      patientDetailsStore.loadPatientDetails(patientId);
      patientReportsStore.loadpatientOverviewData(patientId);

      return () => {
        patientReportsStore.reset();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId]);

    const createNewReport = useCallback(() => {
      if (patientReportsStore.selectedReports.size > 0) {
        displayConfirmationModel(
          'mergeReports',
          undefined,
          patientReportsStore.getSelectedReports.some(
            (report) => report.description?.isCompleted
          )
            ? ConfirmationTitle.Completed_Reports_Merge
            : ConfirmationTitle.Merging_Reports
        );
        return;
      }
      patientReportsStore.createNewReport(
        patientReportsStore.selectedReportedRecordings.size > 0
          ? patientReportsStore.getSelectedReportedRecordings.map((rec) => {
              return rec.studyId;
            })
          : patientReportsStore.getSelectedUnreportedRecordings.map((rec) => {
              return rec.studyId;
            })
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const moveRecordings = (descriptionId?: number) => {
      const selectedReportedRecordingsLength =
        patientReportsStore.selectedReportedRecordings.size;
      if (selectedReportedRecordingsLength) {
        const report = patientReportsStore.patientReports.get(
          patientReportsStore.getReportByRecording(
            patientReportsStore.getSelectedReportedRecordings[0].studyId
          )!
        );
        if (
          report?.studies.length === 1 ||
          selectedReportedRecordingsLength === report?.studies.length
        ) {
          displayConfirmationModel(
            'moveRecordings',
            descriptionId,
            ConfirmationTitle.Removing_All_Recordings
          );
          return;
        }
      }
      onMoveRecordings(descriptionId);
    };

    const onMoveRecordings = useCallback(
      (descriptionId?: number) => {
        patientReportsStore.moveRecordings(
          patientId,
          patientReportsStore.selectedReportedRecordings.size > 0
            ? patientReportsStore.getSelectedReportedRecordings.map((rec) => {
                return rec.studyId;
              })
            : patientReportsStore.getSelectedUnreportedRecordings.map((rec) => {
                return rec.studyId;
              }),
          descriptionId
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [patientId]
    );

    const reopenCompletedReport = (descriptionId: number) => {
      displayConfirmationModel(
        'reopenReport',
        descriptionId,
        ConfirmationTitle.Reopen_Report
      );
    };

    const deleteReport = (descriptionId: number) => {
      displayConfirmationModel(
        'deleteReport',
        descriptionId,
        ConfirmationTitle.Delete_Report
      );
    };

    const onModelClose = () => {
      setShowConfirmationModel(false);
    };

    const onModelSubmit = useCallback(() => {
      switch (confirmationModalContent.actionType) {
        case 'deleteReport':
          patientReportsStore.deleteReport(
            confirmationModalContent.destinationReport!
          );
          break;
        case 'reopenReport':
          patientReportsStore.reOpenDescription(
            confirmationModalContent.destinationReport!
          );
          break;
        case 'moveRecordings':
          onMoveRecordings(confirmationModalContent.destinationReport);
          break;
        case 'mergeReports':
          patientReportsStore.MergeReports(
            patientReportsStore.getSelectedUnreportedRecordings.map((rec) => {
              return rec.studyId;
            }),
            patientReportsStore.getSelectedReports.map(
              (report) => report.description!.descriptionId
            )
          );
          break;
        default:
          setShowConfirmationModel(false);
      }
      setShowConfirmationModel(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      confirmationModalContent.actionType,
      confirmationModalContent.destinationReport
    ]);

    const displayConfirmationModel = (
      actionType: string,
      descriptionId: number | undefined,
      confirmationText: string
    ) => {
      setConfirmationModalContent({
        confirmationText: confirmationText,
        actionType: actionType,
        destinationReport: descriptionId
      });
      setShowConfirmationModel(true);
    };

    return (
      <LoadingOverlay loading={isPatientOverviewLoading}>
        <DndProvider backend={HTML5Backend}>
          <ConfirmationModal
            onSubmit={onModelSubmit}
            cancelButtonTitle={t('Cancel')}
            submitButtonTitle={t('Proceed')}
            visible={showConfirmationModel}
            icon={IconType.Warning}
            title={t(confirmationModalContent.confirmationText)}
            handleVisible={onModelClose}
            onCancel={onModelClose}
          />
          <CustomDragLayer />
          <PatientDetailsHeader
            patientDetails={patientDetails}
            patientPropertyCodings={patientPropertyCodings}
          />
          <div className={styles['patient-overview-page']}>
            <UnreportedRecordings
              recordings={unreportedRecordings}
              moveRecordings={moveRecordings}
            />
            <div className={styles['reports-section']}>
              <div
                className={cn(
                  styles['reports-section-header'],
                  styles['flex-center']
                )}
              >
                <p className={styles['section-title']}>{t('Reports')}</p>
                <div
                  className={cn(
                    styles['flex-center'],
                    styles['reports-section-header-actions']
                  )}
                >
                  {(selectedRecordings > 0 ||
                    patientReportsStore.selectedReports.size > 0) && (
                    <div>
                      <Button
                        icon={IconType.Close}
                        size={ButtonSize.Small}
                        onClick={() => patientReportsStore.discardSelections()}
                        title={t('Discard selection')}
                        theme={ButtonTheme.SecondaryTransparent}
                      />
                    </div>
                  )}
                  {patientReportsStore.selectedReportedRecordings.size > 0 && (
                    <Button
                      size={ButtonSize.Regular}
                      title={t('Remove from report')}
                      onClick={() => moveRecordings()}
                      className={styles['unreport-btn']}
                    />
                  )}
                  <Button
                    theme={ButtonTheme.Primary}
                    size={ButtonSize.Regular}
                    title={t('Create new report')}
                    onClick={createNewReport}
                    disabled={
                      (!selectedRecordings &&
                        patientReportsStore.selectedReports.size < 2) ||
                      patientReportsStore.getSelectedReportedRecordings.some(
                        (study) => !study.isOnline
                      )
                    }
                  />
                </div>
              </div>
              {patientReports.length > 0 ? (
                <div className={styles['reports-scroll-div']}>
                  {patientReports.map((report: Report) => (
                    <ReportTile
                      key={report.description?.descriptionId}
                      sessionHaveEditLock={currentSessionHasLock}
                      report={report}
                      moveRecordings={moveRecordings}
                      reopenCompletedReport={reopenCompletedReport}
                      deleteReport={deleteReport}
                    />
                  ))}
                </div>
              ) : (
                <div style={{ height: '35vh' }}>
                  {!patientReportsStore.patientOverviewLoading && (
                    <GeneralError
                      className={styles['general-error']}
                      icon={IconType.Empty}
                      title={t('You have no reports')}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </DndProvider>
      </LoadingOverlay>
    );
  })
);
