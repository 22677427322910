import React, { useCallback, useEffect } from 'react';
import { generateSettingsConfigKey } from 'components/Category/helpers';
import { CategoryHeader } from 'components/CategoryHeader';
import { EventsTree } from 'components/EventsTree';
import { Folder } from 'components/FindingFolder';
import { Study } from 'entities/Study.entity';
import { EventTreeSettingsType } from 'enums/EventTreeSettingsType.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { observer } from 'mobx-react-lite';
import { EventCodesTreeItem } from 'stores/findings';

import { useAggregateFindings } from './useAggregateFindings';

interface Props {
  parentCodingId?: number;
  readOnly: boolean;
  data: EventCodesTreeItem;
  activeDescriptionId: number;
  studyId?: Study['studyId'];
}

export const Category: React.FC<Props> = observer(
  ({ data, activeDescriptionId, parentCodingId, readOnly }) => {
    const findingsStore = useStore(StoreType.Findings);

    const settingsConfigKey = generateSettingsConfigKey(
      data.item.eventCodeId,
      parentCodingId
    );

    const categoriesState = findingsStore.eventTreeState.getEventTreeEntityStateById(
      activeDescriptionId,
      EventTreeSettingsType.CategoriesState
    );

    const nestedFoldersSubTree = data.children.filter((child) => {
      return (
        (child.item.isFolder || child.item.isExpandableNodeFindingTreeView) &&
        child.item.isDependentOnEventCoding
      );
    });

    const totalFindings = useAggregateFindings({
      data,
      activeDescriptionId,
      parentCodingId,
      nestedFoldersSubTree
    });

    const totalChildren = [
      ...totalFindings,
      ...(data.isNested ? nestedFoldersSubTree : [])
    ];

    const descriptionStatus = findingsStore.descriptionStatuses.get(
      activeDescriptionId
    );

    const showCategoryWarning = () => {
      for (const each of totalChildren) {
        if (each.eventCoding?.eventCodingId) {
          const codingStatus = descriptionStatus?.getEventCodingStatus({
            eventCodingId: each.eventCoding.eventCodingId
          });
          if (codingStatus) {
            return true;
          }
        }
      }
      return;
    };

    useEffect(() => {
      if (
        activeDescriptionId &&
        (!categoriesState ||
          (categoriesState && categoriesState[settingsConfigKey] === undefined))
      ) {
        findingsStore.eventTreeState.updateEventTreeSettingsConfig({
          descriptionId: activeDescriptionId,
          entityId: settingsConfigKey,
          settingKey: EventTreeSettingsType.CategoriesState,
          expanded: data.isNested ? false : !!totalFindings.length
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isActive = categoriesState
      ? categoriesState[settingsConfigKey]
      : false;

    const onToggleFolder = useCallback(() => {
      findingsStore.eventTreeState.updateEventTreeSettingsConfig({
        descriptionId: activeDescriptionId,
        entityId: settingsConfigKey,
        settingKey: EventTreeSettingsType.CategoriesState,
        expanded: !isActive
      });
    }, [
      findingsStore.eventTreeState,
      isActive,
      settingsConfigKey,
      activeDescriptionId
    ]);

    const headerProps = {
      activeDescriptionId,
      eventCode: data.item,
      parentCodingId,
      readOnly,
      hasChildren: !!totalChildren.length,
      onToggleFolderByHotKey: onToggleFolder,
      isActive,
      childrenHasWarning:
        totalChildren.length > 0 ? showCategoryWarning() : false
    };

    if (!totalChildren.length && !data.isNested) {
      return null;
    }

    return (
      <Folder
        header={CategoryHeader}
        headerProps={headerProps}
        onToggleFolder={onToggleFolder}
        toggleDisabled={!totalChildren.length}
        isActive={!totalChildren.length ? false : isActive}
      >
        <EventsTree
          readOnly={readOnly}
          eventTreeData={totalChildren}
          parentCodingId={parentCodingId}
        />
      </Folder>
    );
  }
);
