import { EditLockStatus } from 'entities/EditLockStatus.entity';
import { PatientDetails } from 'entities/PatientDetails.entity';
import { RealTimeUpdateSendMessages } from 'enums/RealTimeUpdateType.enum';
import { StoreType } from 'enums/StoreType.enum';
import { action, makeObservable, observable } from 'mobx';
import { RTUManager } from 'services/RealTimeUpdatesManager';
import { stores } from 'stores';
import { BaseStore } from 'stores/BaseStore';

export class patientLockDetails implements BaseStore {
  @observable
  patientLockDetails: Map<
    PatientDetails['patientId'],
    EditLockStatus
  > = new Map();

  @observable
  lastOpenedPatientId: number | null = null;

  @observable
  patientLockLoading: boolean = false;

  constructor() {
    makeObservable(this);
    this.reset();
  }

  @action reset() {
    this.patientLockDetails = new Map();
    this.lastOpenedPatientId = null;
    this.patientLockLoading = false;
  }

  @action async sendPatientLockMessage(
    patientId: number,
    message: RealTimeUpdateSendMessages,
    showLoader: boolean = true
  ) {
    try {
      if (showLoader) this.patientLockLoading = true;
      const response: EditLockStatus = await RTUManager.sendMessageForResponse(
        message,
        {
          patientId: patientId
        }
      );
      this.patientLockDetails.set(patientId, response);
    } catch (err: any) {
      stores[StoreType.Messages].addMsgError(
        `signalr_patientlock_${patientId}`,
        { ...err, fullMessage: err.message }
      );
    } finally {
      this.patientLockLoading = false;
    }
  }
}
