import React from 'react';
import { useParams } from 'react-router-dom';
import { Category } from 'components/Category';
import {
  groupEventTreeDataByIsFolderProp,
  groupEventTreeDataByLevelOfNesting,
  isMoveDownDisabled,
  isMoveUpDisabled
} from 'components/EventsTree/helpers';
import { Finding } from 'components/Finding';
import { GroupedFindings } from 'components/GroupedFindings';
import { EventCodesTreeItem } from 'stores/findings';

interface Props {
  eventTreeData: EventCodesTreeItem[];
  parentCodingId?: number;
  readOnly?: boolean;
}

export const EventsTree: React.FC<Props> = React.memo(
  ({ eventTreeData, parentCodingId, readOnly = false }) => {
    const { id: descriptionId } = useParams<{ id: string }>();
    const activeDescriptionId = parseInt(descriptionId);

    const [nestedFindings, eventCodes] = groupEventTreeDataByLevelOfNesting(
      eventTreeData
    );

    const [findings, folders] = groupEventTreeDataByIsFolderProp(
      nestedFindings.length === 1
        ? [...nestedFindings, ...eventCodes]
        : eventCodes
    );

    return (
      <>
        {folders.map((code) => {
          if (!code.item.showInFindingTreeView) {
            return (
              <EventsTree
                readOnly={readOnly}
                parentCodingId={parentCodingId}
                eventTreeData={code.children}
                key={code.item.eventCodeId}
              />
            );
          }

          return (
            <Category
              data={code}
              readOnly={readOnly}
              key={code.item.eventCodeId}
              parentCodingId={parentCodingId}
              activeDescriptionId={activeDescriptionId}
            />
          );
        })}

        {nestedFindings.length > 1 && (
          <GroupedFindings
            readOnly={readOnly}
            nestedFindings={nestedFindings}
            parentCodingId={parentCodingId}
            activeDescriptionId={activeDescriptionId!}
          />
        )}

        {findings.map((code, index) => (
          <Finding
            data={code}
            index={index}
            readOnly={readOnly}
            findingsList={findings}
            parentCodingId={parentCodingId}
            itemIndex={
              findings.length > 1
                ? code.item.getSortingNumber(index + 1)
                : undefined
            }
            key={code.eventCoding?.eventCodingId || code.item.eventCodeId}
            isMoveUpDisabled={isMoveUpDisabled(index, findings.length)}
            isMoveDownDisabled={isMoveDownDisabled(index, findings.length)}
            activeDescriptionId={activeDescriptionId!}
          />
        ))}
      </>
    );
  },
  (prevProps, nextProps) =>
    JSON.stringify(prevProps.eventTreeData) ===
      JSON.stringify(nextProps.eventTreeData) &&
    prevProps.parentCodingId === nextProps.parentCodingId &&
    prevProps.readOnly === nextProps.readOnly
);
