import React, { useCallback } from 'react';
import { IndexContainer } from '@holberg/ui-kit';
import {
  groupFindingsBySortOrder,
  isMoveDownDisabled,
  isMoveUpDisabled,
  reorderEventTreeData
} from 'components/EventsTree/helpers';
import { Finding } from 'components/Finding';
import { Description } from 'entities/Description.entity';
import { Study } from 'entities/Study.entity';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { EventCodesTreeItem } from 'stores/findings';

import styles from 'components/GroupedFindings/GroupedFindings.module.scss';

interface Props {
  parentCodingId?: number;
  studyId?: Study['studyId'];
  readOnly: boolean;
  nestedFindings: EventCodesTreeItem[];
  activeDescriptionId: Description['descriptionId'];
}

export const GroupedFindings: React.FC<Props> = ({
  readOnly,
  nestedFindings,
  parentCodingId,
  activeDescriptionId
}) => {
  const groupedFindings = groupFindingsBySortOrder(nestedFindings);
  const findingsStore = useStore(StoreType.Findings);

  const reorderGroupedItems = useCallback(
    (
      list: EventCodesTreeItem[],
      getNewPositionIndex: (index: number) => number
    ) => {
      const sortedList = reorderEventTreeData<EventCodesTreeItem[]>(
        groupedFindings,
        list,
        getNewPositionIndex
      ).map((groupedList, index) =>
        groupedList.map((item) => ({
          eventCodingId: item.eventCoding!.eventCodingId,
          sortOrder: index + 1
        }))
      );

      findingsStore.updateEventCodingsSortOrder({
        data: sortedList.flat(),
        descriptionId: activeDescriptionId
      });
    },
    [activeDescriptionId, findingsStore, groupedFindings]
  );

  const moveUp = useCallback(
    (list: EventCodesTreeItem[]) => {
      reorderGroupedItems(list, (itemIndex: number) => itemIndex - 1);
    },
    [reorderGroupedItems]
  );

  const moveDown = useCallback(
    (list: EventCodesTreeItem[]) => {
      reorderGroupedItems(list, (itemIndex: number) => itemIndex + 1);
    },
    [reorderGroupedItems]
  );

  if (!groupedFindings.length) {
    return null;
  }

  return (
    <>
      {groupedFindings.map((findingsGroup, index: number) => (
        <IndexContainer
          key={index}
          onMoveUp={() => moveUp(findingsGroup)}
          onMoveDown={() => moveDown(findingsGroup)}
          title={findingsGroup[0].item.getSortingNumber(index + 1)}
          className={styles['index-container']}
          isMoveUpDisabled={
            isMoveUpDisabled(index, groupedFindings.length) || readOnly
          }
          isMoveDownDisabled={
            isMoveDownDisabled(index, groupedFindings.length) || readOnly
          }
        >
          <div
            data-testid='nested-finding'
            className={styles['nested-finding']}
          >
            {findingsGroup.map((finding: EventCodesTreeItem) => (
              <Finding
                index={index}
                isMoveUpHidden
                isMoveDownHidden
                readOnly={readOnly}
                isSimultaneous={findingsGroup.length > 1}
                data={finding}
                parentCodingId={parentCodingId}
                activeDescriptionId={activeDescriptionId}
                headerClassName={styles['finding-header']}
                key={
                  finding.eventCoding?.eventCodingId || finding.item.eventCodeId
                }
              />
            ))}
          </div>
        </IndexContainer>
      ))}
    </>
  );
};
